import React from 'react';
import { Banner, Layout, Navbar } from '@components';
import { useSiteMetadata } from '@hooks';
import { siteUnlockTime } from '@variables';
import { Helmet } from 'react-helmet';
import '@scss/404.scss';

const shouldShowSite = () => new Date() >= new Date(siteUnlockTime.wholeSite);

const Error404Page = () => {
  const site = useSiteMetadata();

  return <Layout className="error-page">
    <Helmet>
      <title>Error 404 - {site.title}!</title>
    </Helmet>
    <Navbar showNavigation={shouldShowSite()} />
    <Banner />
  </Layout>;
};

export default Error404Page;
